import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import styled from "styled-components";
import Reminder from "./layout/Reminder";
import { Header } from "./layout";
import Login from "./Login";
import Aside from "./common/menu/Aside";
import Error from "./Error/Error";
import { useLoading, useToken } from "./store/store";
import "react-multi-carousel/lib/styles.css";
import "tippy.js/dist/tippy.css";
import { FileExport } from "@bjsdistribution/dms-export";
import EventDashboard from "@bjsdistribution/dms-event-dashboard";
import { CustomerConfigComponent } from "@bjsdistribution/dms-customer-config";
const ProductInformation = React.lazy(
  () => import("@bjsdistribution/dms-product-information")
);
const Debrief = React.lazy(() => import("@bjsdistribution/dms-finance"));
const RouteModule = React.lazy(() => import("@bjsdistribution/dms-routes"));
const Order = React.lazy(() => import("@bjsdistribution/dms-orders"));
const Flags = React.lazy(() => import("@bjsdistribution/dms-flags"));
const InvoiceModule = React.lazy(() => import("@bjsdistribution/dms-invoice"));
const OffShoreModule = React.lazy(
  () => import("@bjsdistribution/dms-offshore")
);
const ReturnClient = React.lazy(
  () => import("@bjsdistribution/dms-client-return")
);

const AppRouter: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [token] = useToken();
  const [loading] = useLoading();

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  const [isLogin, setIsLogin] = useState<boolean>(false);
  useEffect(() => {
    const username = localStorage.getItem("username");
    if (username) {
      setIsLogin(true);
    } else {
      console.log("Need to redirect login");
    }
  }, [isLogin]);

  const LoadingMessage = () => <div>Loading...</div>;

  return (
    <>
      <Router>
        <Login setIsLogin={setIsLogin} />\
        {isLogin && (
          <div className="coverDms">
            <Reminder />
            <MainContainer>
              <div className="dashboard">
                <Header />
                <div
                  className={
                    isExpanded ? "placeHolderWrapper" : "placeHolderWrapper "
                  }
                >
                  <Aside toggleSidebar={toggleSidebar} />
                  <div className="placeHolder">
                    <Suspense fallback={<LoadingMessage />}>
                      <Routes>
                        <Route path="/" element={<Navigate to="/routes" />} />
                        <Route
                          path="/product/*"
                          element={<ProductInformation />}
                        />
                        <Route path="/finance/*" element={<Debrief />} />
                        <Route path="/routes/*" element={<RouteModule />} />

                        <Route path="/orders/*" element={<Order />} />
                        <Route path="/flag/*" element={<Flags />} />
                        <Route
                          path="finance/invoice/*"
                          element={<InvoiceModule />}
                        />
                        <Route
                          path="/offshore/*"
                          element={<OffShoreModule />}
                        />
                        <Route
                          path="/orders/view/return/*"
                          element={<ReturnClient />}
                        />

                        <Route path="/file-export" element={<FileExport />} />
                        <Route
                          path="/event-dashboard/*"
                          element={<EventDashboard />}
                        />
                        <Route
                          path="/customer-config/*"
                          element={<CustomerConfigComponent />}
                        />
                        <Route path="*" element={<Error />} />
                      </Routes>
                    </Suspense>
                  </div>
                </div>
              </div>
            </MainContainer>
          </div>
        )}
      </Router>
    </>
  );
};

const MainContainer = styled.div`
  /* display: flex; */

  .dashboard {
    flex: 1;
    transition: all ease 0.5s;
    .placeHolder {
      padding: 72px 20px 10px 20px;
      flex: 1 1 0%;
    }
  }

  .placeHolderWrapper.collapsed {
    width: 100vw;
  }
  .placeHolderWrapper {
    /* display: flex; */
    width: 100%;
    padding-left: 63px;
    transition: all ease 0.5s;
    &.expanded {
      padding-left: 61px;

      .submenu {
        transform: translateX(-130%);
      }
      .toggle-icon {
        right: -71px !important;
      }
    }
  }
`;

export default AppRouter;
