import React, { useEffect, useState } from "react";
import { useAxios } from "@bjsdistribution/dms-component-library";
import { setLoginUserDetails } from "./redux/features/appMangerUtilitySlice";
import { getUrlParams } from "./common/utils";

const Login = ({
  setIsLogin,
}: {
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const ID_TOKEN = "id_token";
  const hash = window.location.hash.substring(1);
  const params = getUrlParams(hash);
  const idToken = params.get(ID_TOKEN);

  const {
    data,
    fetchData,
    loading: ssoLoading,
  } = useAxios<any>({
    endpoint: `sso_auth`,
    method: "GET",
    config: { headers: { Authorization: `Bearer ${idToken}` } },
  });

  useEffect(() => {
    let LoginStatus = localStorage.getItem("username");
    if (!LoginStatus) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (!ssoLoading && data?.username) {
      // @ts-ignore const username =
      localStorage.setItem("username", data?.username);
      setLoginUserDetails(data?.username);
      setIsLogin(true);
    }
  }, [ssoLoading]);

  return <></>;
};

export default Login;
