import { createSlice } from '@reduxjs/toolkit'


export interface UtilityType {
    loginUserDetails: string

}

const initialState: UtilityType = {
    loginUserDetails: ''
}

export const appMangerUtilitySlice = createSlice({
    name: 'appMangerUtilitySlice',
    initialState,
    reducers: {


        setLoginUserDetails: (state, action) => {
            state.loginUserDetails = action.payload
        },

    },
})

export const { setLoginUserDetails } = appMangerUtilitySlice.actions

export default appMangerUtilitySlice.reducer

